<template>
    <div id="container">
        <div class="vx-card p-6">
            <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full">
                    <TitleScreen></TitleScreen>
                </div>
            </div>
            <vs-divider></vs-divider>
            <div class="vx-row mt-4">
                <div class="vx-col md:w-1/4 w-full">
                    <label class="vs-input--label">{{ $t('menuOrganization.branch') }}</label>
                    <vs-input class="w-full" :disabled="true" v-model="deviceData.organizationBranchName"></vs-input>
                </div>
                <div class="vx-col md:w-1/4 w-full">
                    <label class="vs-input--label">{{this.$t('menuOrganization.deviceId')}}</label>
                    <vs-input class="w-full" :disabled="true" v-model="deviceData.deviceId"></vs-input>
                </div>
                <div class="vx-col md:w-1/2 w-full">
                    <label class="vs-input--label">{{this.$t('menuOrganization.deviceName')}}</label>
                    <vs-input v-validate="'required'" class="w-full"
                              data-vv-validate-on="blur"
                              autocomplete="off"
                              name="deviceName"
                              v-model="deviceData.deviceName"></vs-input>
                    <span class="text-danger text-sm">{{
                            errors.first("deviceName")
                        }}</span>
                </div>
            </div>
            <div class="vx-row mt-4 flex flex-wrap items-center justify-end">
                <vs-button class="" @click="saveChange">
                    {{ $t('button.update') }}
                </vs-button>
                <vs-button class="ml-4" type="border" color="warning" @click="$router.push('/user/manage-hardware-devices')">
                    {{ $t('button.back') }}
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>

import vSelect from 'vue-select'
import TitleScreen from "../../../layouts/main/TitleScreen"
import utils from "../../../components/utils";

export default {
    data() {
        return{
            deviceData: {
                organizationBranchName: null
            },
            organizationBranchOptions: []
        }
    },

    components: {
        TitleScreen,
        vSelect
    },
    created() {
        let id = this.$route.query.id;
        let organizationBranchId = this.$route.query.organizationBranchId;

        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        if (branchList.length === 0) {
            return this.$vs.notify({
                text: this.$t('menuOrganization.noBranch'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
        }
        this.organizationBranchOptions = branchList.map(item => {
            return {value: item.id, label: item.organizationBranchName}
        });

        if (id) {
            setTimeout(() => {
                this.$vs.loading();
                this.$crm.get(`/device/hardware/find-by-id?id=${id}&organizationBranchId=${organizationBranchId}`).then((response) => {
                    this.deviceData = response.data;
                    let orgBranch = this.organizationBranchOptions.find(x => x.value == organizationBranchId);
                    this.deviceData.organizationBranchName = orgBranch ? orgBranch.label : null;
                    this.$vs.loading.close();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }, 0)
        }
    },

    methods: {
        saveChange() {
            this.$vs.loading();
            let params = {
                id: this.deviceData.id,
                organizationBranchId: this.deviceData.organizationBranchId,
                deviceName: this.deviceData.deviceName
            };
            this.$crm.post(`/device/hardware/update-name`, new URLSearchParams(params)).then((response) => {
                this.$vs.loading.close();
                this.$router.push('/user/manage-hardware-devices');
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    }
}
</script>
